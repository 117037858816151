@charset "UTF-8";

root{
  --primary: #3c4043;
  --secondary: #2c3e50;
}

body {
  font-family: 'Montserrat', sans-serif;
  color: #3c4043;
}

.navbar .active {
  font-weight: bold;
}

.navbar-light .navbar-toggler {
  border-color: #ffffff;
}

.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3e%3cpath stroke='rgba(0, 0, 0, 0.9)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}

.navbar-light .navbar-nav .nav-link {
  float: left;
}

.first-section {
  padding-top: 5rem;
}
.section {
  padding-top: 5rem;
  padding-bottom: 1rem;
}

header {
  font-size: 20px;
  padding-bottom: 5rem;
}

h1 {
  font-size: 56px;
}

header h3 {
  font-size: 12px;
  font-style: italic;
}

header p {
  padding-top: 1rem;
}

.header-profile-picture {
  width: 15rem;
  border-radius: 50%;
}

.btn-contact {
  margin-right: 2rem;
}

.portfolio-title {
  margin-bottom: 3rem;
}

.portfolio-image-wrapper {
  position:relative;
  overflow:hidden;
  padding-bottom:100%;
}
.portfolio-image-wrapper img{
  position:absolute;
}

.portfolio .portfolio-item {
  cursor: pointer;
  position: relative;
  display: block;
  max-width: 25rem;
  overflow: hidden;
}
.portfolio .portfolio-item .portfolio-item-caption {
  position: absolute;
  top: 0;
  left: 0;
  transition: all 0.2s ease-in-out;
  opacity: 0;
  background-color: rgba(60, 64, 67, 0.8);
}
.portfolio .portfolio-item .portfolio-item-caption:hover {
  opacity: 1;
}
.portfolio .portfolio-item .portfolio-item-caption .portfolio-item-caption-content {
  font-size: 1.5rem;
}

.portfolio-modal .portfolio-modal-title {
  font-size: 2.25rem;
  line-height: 2rem;
}

.img-fluid {
  max-width: 140% !important;
}

.floating-label-form-group {
  border-bottom: 1px solid #e9ecef;
}

.floating-label-form-group label {
  opacity: 0;
}

.floating-label-form-group input {
  border: none;
  font-size: 1.5rem;
}

.floating-label-form-group input,
.floating-label-form-group textarea {
  box-shadow: none !important;
  padding-right: 0;
  padding-left: 0;
  border: none;
  font-size: 1.5em;
  resize: none;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active  {
  -webkit-box-shadow: 0 0 0 30px white inset !important;
  -webkit-text-fill-color: #3c4043 !important;
}

.contact-submit {
  float: left;
}

footer {
  padding-top: 1rem;
  margin-bottom: 3rem;
}

.btn-social {
  border-radius: 100%;
  display: inline-flex;
  width: 3.25rem;
  height: 3.25rem;
  font-size: 1.25rem;
  justify-content: center;
  align-items: center;
}

footer a {
  color: #3c4043;
}

footer a:hover {
  color: #23272b;
  text-decoration: none;
}

footer h2 {
  padding-top: 1rem;
  padding-bottom: 1rem;
}


@media (min-width: 992px) {
  .portfolio-modal .portfolio-modal-title {
    font-size: 3rem;
    line-height: 2.5rem;
  }
}
.portfolio-modal .close {
  position: absolute;
  z-index: 1;
  right: 1.5rem;
  top: 1rem;
  font-size: 3rem;
  line-height: 3rem;
  color: #1abc9c;
  opacity: 1;
}

@media (max-width: 808px) {

  h1 {
    font-size: 42px;
  }
}

@media (max-width: 540px) {

  .first-section {
    padding-top: 3rem;
    padding-bottom: 1rem;
  }

  header {
    font-size: 18px;
    padding-bottom: 1rem;
  }

  h1 {
    font-size: 32px;
  }
}
